import React, { useEffect }  from "react"
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import Qr from "../_utils/Qr"
import Database from "../_utils/Database"
import { goto } from "../_utils/Misc"
import { getGame } from "../_services/game.service"
import Home from "./home"

const Index = () => {
    let userData = !!Database.getUserToken() && jwtDecode(Database.getUserToken())
    useEffect(() => {
        // Check if token has expired
        if(!!userData && userData.exp < moment(new Date()).unix()) {
            Database.removeUserToken()
        }

        // Fetch the game payload
        if (!!Qr.getQueryParams && !!Qr.getQueryParams().campaignId) {
            let gameSuggestedGameParams = {
                variant: 'quiz-menu',
                campaign_id: Qr.getQueryParams().campaignId
            }

            getGamePayload(gameSuggestedGameParams)
        } else {
            checkRedirection()
        }
    }, [])

    const getGamePayload = (storedParams) => {
        return getGame(storedParams).then(response => {
            !!response && !!response.levels ? Qr.storeGame(response) : Qr.storeGame(undefined)

            checkRedirection()
        })
    }
    const checkRedirection = () => {
        // Check for QR parameters
        if (Qr.hasQueryParams()) {

            // Save query data & redirect to reward page
            Qr.storeParams()

            // Check where to go
            if (Qr.getQueryParams().accessToken) {
                // Login
                goto("/login-fetch/")
            } else if (Database.getUserToken()) {
                !!Qr.getQueryParams && Qr.getQueryParams().game === 'true' && !!Qr.getGameParams()
                    ? goto("/taste-game/")
                    : !!Qr.getQueryParams && Qr.getQueryParams().game_suggest === 'true' && !!Qr.getGameParams()
                    ? goto("/suggest-menu/")
                    : goto("/qr-rewards-fetch/")
            } else {
                // If it`s not logged go to registration
                !!Qr.getQueryParams && Qr.getQueryParams().game === 'true' && !!Qr.getGameParams()
                ? goto("/taste-game/")
                : !!Qr.getQueryParams && Qr.getQueryParams().game_suggest === 'true' && !!Qr.getGameParams()
                ? goto("/suggest-menu/")
                : goto("/reward-lander/")
            }
        } else {
            // Redirect to home page
            goto("/home/")
        }
    }

    return (<Home/>)
}

export default Index
